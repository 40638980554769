import * as React from 'react';

import { TextBlock } from 'stile-shared/src/components/2020/blocks/TextBlock';

import timeline from '../../../../stile-shared/assets/images/nevada-oregon-homepages/timeline.svg';

export function StileRoadmapBlock() {
  return (
    <TextBlock textAlign="center" title="How do I get Stile at my school?">
      <img
        src={timeline}
        alt="A flowchart with showing the process of getting Stile at your school. Once a user has clicked the free trial button, Kat will get in contact and help you get set up to teach with Stile"
      />
    </TextBlock>
  );
}
